<template>
  <div class="h-100">
    <div class="row justify-content-center">
      <div
        class="col-12 col-md-6 align-self-center text-center"
        v-if="success"
        ref="thanks"
      >
        <h1>Tack för din anmälan!</h1>
        <svg
          v-if="success"
          class="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            class="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            class="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
      <div v-else class="col-12 col-md-6" ref="form">
        <div
          class="alert alert-warning"
          role="alert"
          v-show="success === false"
        >
          Något gick fel när anmälan skulle skickas in. Kontrollera att alla
          fält är ifyllda korrekt, och prova igen. Går det inte att skicka in
          din anmälan genom detta formulär, skicka den i ett mejl till 
          IT-ansvarig Andreas Törnkvist på <a href="mailto: andreas.tornkvist@ungaforskare.se">andreas.tornkvist@ungaforskare.se</a>.
          
        <!--
          {{ competition.head_of_selections_title }}
          {{ competition.head_of_selections_name }} på
          <a :href="`mailto:${competition.head_of_selections_email}`">{{
            competition.head_of_selections_email
          }}</a>
        -->
        </div>
        <div
          class="alert alert-warning"
          role="alert"
          v-show="Boolean(errorMessage)"
        >
          {{ errorMessage }}
        </div>
        <div v-show="step === 1" key="description">
          <div>
            
            <p>
              Kul att du vill vara med i Utställningen! 🔬 Vi behöver veta lite
              mer om ditt projekt och dig för att kunna hantera din ansökan.
              <!-- Du kan ändra i din anmälan fram till sista ansökningsdag (7 februari 2020), t.ex. om du vill skicka in en uppdaterad rapport, ändra i din projektbeskrivning eller lägga till fler deltagare på projektet. -->
            </p>
            <h4>Instruktionsfilm</h4>
            <p> Se gärna <a href="https://ungaforskare.se/wp-content/uploads/2025/01/instruktionsfilm-anmalan-utstallningen.mp4">denna instruktionsfilm</a> som går igenom vad utställningen är, hur du gör anmälan och vad som är viktigt att tänka på då.</p>
            <h4>Viktig information</h4>
            <p>
              Anmälan är öppen till och med 7 februari (stänger 23:59 det datumet!).
              <!--{{ formatEndDate() }}-->
            </p>
            <p>
              Genom att skicka in anmälan godtar man den information som står
              rörande till exempel hantering av personuppgifter. Om
              projektgruppen består av fler än en person ska samtliga i gruppen
              ta del av informationen.
            </p>

            <p>
              Det kostar inget att anmäla sitt projekt men om du kommer till
              semifinal och final behöver du/ din skola stå för resekostnader.
              För de som kommer till final finns en subventionerad finalavgift
              som för närvarande är 1900 kr per finalist och syftar till kost,
              logi och aktiviteter under finalveckan. Prata gärna med din skola
              om detta redan innan du anmäler ditt projekt.
            </p>

            <p>
              Det tävlingsmaterial som ska skickas in till oss är 1.
              projektrapport/ sammanfattning och 2. Tillståndsnummer i de fall det
              behövs, läs mer nedan.
            </p>

            <p class="font-weight-bold">
              <a
                href="https://ungaforskare.se/utstallningen/tavlingsregler/"
                target="_blank"
                >Läs tävlingsreglerna innan du fyller i anmälan.</a
              >
            </p>
            <!-- <p class="font-weight-bold">
              <a href="/Instruktioner till eleverna.pdf" download
                >Här kan du läsa mer om hur ditt projekt kommer att bedömas.</a
              >
            </p> -->
            <p class="font-weight-bold">
              <a
                href="https://ungaforskare.se/utstallningen/faq/"
                target="_blank"
                rel="noreferrer noopener"
                >Här finns en FAQ med svar på några vanliga frågor.</a
              >
            </p>
            <p>
              Vid frågor kontakta {{ competition.head_of_selections_title }}
              {{ competition.head_of_selections_name }} på
              <a :href="`mailto:${competition.head_of_selections_email}`">{{
                competition.head_of_selections_email
              }}</a
              >.
            </p>
          </div>
        </div>

        <hr />

        <div v-show="step === 1" key="step1">
          <h1>Projektinformation</h1>
          <div class="row">
            <div class="col">
              <form>
                <div class="form-group">
                  <label for="title">
                    Titel
                    <small class="text-muted"
                      >(obligatoriskt, max 200 tecken)</small
                    >
                  </label>
                  <input
                    id="title"
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    v-model="$v.application.title.$model"
                    :class="{
                      'is-invalid': $v.application.title.$error,
                      'is-valid':
                        !$v.application.title.$error &&
                        $v.application.title.$dirty,
                    }"
                  />
                  <div
                    class="small text-danger"
                    v-if="
                      $v.application.title.$dirty &&
                      !$v.application.title.required
                    "
                  >
                    Titel måste anges
                  </div>
                  <div
                    class="small text-danger"
                    v-if="!$v.application.title.maxLength"
                  >
                    Titeln är för lång
                  </div>
                </div>

                <div class="form-group">
                  <label for="description">
                    Beskrivning
                    <small class="text-muted"
                      >(obligatoriskt, max 1000 tecken)</small
                    >
                  </label>
                  <textarea
                    id="description"
                    type="textarea"
                    rows="7"
                    class="form-control"
                    autocomplete="off"
                    v-model="$v.application.summary.$model"
                    :class="{
                      'is-invalid': $v.application.summary.$error,
                      'is-valid':
                        !$v.application.summary.$error &&
                        $v.application.summary.$dirty,
                    }"
                  />
                  <div class="small text-muted text-right">
                    {{ application.summary.length }}/1000
                  </div>
                </div>

                <div class="form-group" v-if="!isDigital">
                  <label for="description"
                    >Om montern behöver tillgång till el, ange anledning</label
                  >
                  <textarea
                    type="textarea"
                    rows="2"
                    class="form-control"
                    autocomplete="off"
                    v-model="application.requiresElectricityBecause"
                  />
                </div>

                <div class="form-group">
                  <label for="subjectField">
                    Ämne
                    <small class="text-muted">(obligatoriskt)</small>
                  </label>
                  <select
                    class="form-control"
                    id="subjectField"
                    v-model="$v.application.subjectId.$model"
                    :class="{
                      'is-invalid': $v.application.subjectId.$error,
                      'is-valid':
                        !$v.application.subjectId.$error &&
                        $v.application.subjectId.$dirty,
                    }"
                  >
                    <option
                      v-for="subject in subjects"
                      :key="subject.id"
                      v-bind:value="subject.id"
                    >
                      {{ subject.name }}
                    </option>
                  </select>
                </div>

                <hr />

                <div class="form-check form-group">
                  <input
                    class="form-check-input"
                    id="isWaterProject"
                    type="checkbox"
                    v-model="application.isWaterProject"
                  />
                  <label for="isWaterProject" class="form-check-label">
                    Projektet handlar om vatten.
                  </label>
                  <br />
                  <small class="text-muted">
                    Berör ditt projekt vatten, vattenfrågor eller
                    vattenkvalitet är du med och tävlar om priset Stockholm
                    Junior Water Prize Sweden. 
                    <a
                      href="https://ungaforskare.se/stipendier/sjwp-sweden/"
                      target="_blank"
                    >Läs mer</a>.
                  </small>
                </div>

                <hr />

                <div class="form-check form-group">
                  <input
                    class="form-check-input"
                    id="needsPermit"
                    type="checkbox"
                    v-model="application.needsPermit"
                  />
                  <label for="needsPermit" class="form-check-label">
                    Projektet kräver tillstånd från
                    myndigheter eller annan instans.
                    <br /><small class="text-muted">
                      Om du har ett tillstånd för ditt projekt utifrån vad som står beskrivet under “Ansvar och tillstånd” i 
                      <a
                        href="https://ungaforskare.se/utstallningen/tavlingsregler/"
                        target="_blank"
                        >tävlingsreglerna</a
                      >
                      så skriv tillståndets nummer här som ett underlag för att tillståndet finns. Du behöver inte bifoga ett eventuellt tillstånd med din anmälan till Utställningen Unga Forskare men ska vara beredd att styrka tillstånd med kort varsel om juryn begär detta under tävlingens gång.
                    </small>
                  </label>
                </div>

                <div v-if="application.needsPermit" class="form-group">
                  <label for="permitNumber">Tillståndsnummer</label>
                  <input
                    type="text"
                    id="permitNumber"
                    v-model="application.permitNumber"
                    class="form-control"
                  />
                </div>

                <hr />

                <div class="form-group">
                  <label>
                    Rapport/sammanfattning och tävlingsmaterial
                    <small class="text-muted"
                      >(obligatoriskt, endast PDF)</small
                    >
                  </label>
                  <div class="small">
                    Döp rapporten till "namn, titel, datum", alltså exempelvis
                    "Pelle Pettersson, Havets skatter, 20250131"
                  </div>
                  <project-file-upload
                    v-bind:signedId.sync="$v.application.report.$model"
                    @blob-uploaded="setFilename"
                  />
                  <div
                    class="small text-danger"
                    v-if="$v.application.report.$error"
                  >
                    Du måste ladda upp en rapport eller sammanfattning.
                  </div>
                </div>

                <div class="form-group">
                  <label for="reportLanguage">
                    Rapportspråk
                    <small class="text-muted">(obligatoriskt)</small>
                  </label>
                  <select
                    class="form-control"
                    id="reportLanguage"
                    v-model="application.reportLanguage"
                  >
                    <option value="Svenska">Svenska</option>
                    <option value="Engelska">Engelska</option>
                  </select>
                </div>

                <hr />

                <div class="form-check form-group">
                  <input
                    class="form-check-input"
                    id="acceptedRules"
                    type="checkbox"
                    v-model="application.acceptedRules"
                  />
                  <label for="acceptedRules" class="form-check-label">
                    Jag intygar att jag läst och accepterar
                    <a
                      href="https://ungaforskare.se/utstallningen/tavlingsregler/"
                      target="_blank"
                      >tävlingsreglerna</a
                    >
                    <br /><small class="text-muted">
                      (obligatoriskt)
                    </small>
                  </label>
                  <div
                    class="small text-danger"
                    v-if="$v.application.acceptedRules.$error"
                  >
                    Måste vara accepterat
                  </div>
                </div>

                <hr />

                <div class="form-group">
                  <label>Semifinalsort - Välj de som ligger närmast där du bor!</label>
                  <br />
                  <div class="small">
                    Välj upptill tre semifinalsorter och tänk på att du ska
                    kunna resa fysiskt till semifinalen på den orten om du får
                    semifinalsplats. Du eller din skola behöver bekosta resan
                    själv.
                  </div>
                  <div class="small" v-if="isDigital">
                    Välj den semifinalsort som är närmast din skola.
                    <small class="text-muted">(obligatoriskt)</small>
                  </div>
                  <div
                    class="small text-danger"
                    v-if="$v.application.localContestPriorities.$error"
                  >
                    Du måste välja en semifinalsort som förstahandsval.
                  </div>

                  <template v-for="n in numLocalContestPriorities">
                    <small :key="n" v-if="!isDigital">
                      {{ numToOrderNumber(n) }} hand
                      <small class="text-muted" v-if="n === 1"
                        >(obligatoriskt)</small
                      >
                    </small>
                    <select
                      :key="n * 1000"
                      class="form-control"
                      v-model="
                        application.localContestPriorities[n - 1].localContestId
                      "
                    >
                      <option
                        v-for="localContest in getFilteredLocalContests(n)"
                        :key="localContest.id"
                        v-bind:value="localContest.id"
                      >
                        {{ localContest.name }} ({{
                          localContest.starts_at.split("T")[0]
                        }})
                      </option>
                    </select>
                  </template>
                  <br />
                  <div class="form-check form-group">
                    <input
                      class="form-check-input"
                      id="infoSeminfinals"
                      type="checkbox"
                    />
                    <label for="infoSeminfinals" class="form-check-label"
                      >Semifinalerna är 28 februari - 7 mars. Flagga för det i din kalender redan nu och informera din skola!
                    </label>
                  </div>
                </div>

                <hr />

                <div class="form-group">
                  <label for="supervisor-name">
                    Lärarhandledarens namn
                    <small class="text-muted">(obligatoriskt)</small></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    id="supervisor-name"
                    v-model="application.supervisor.name"
                    @input="$v.application.supervisor.name.$touch"
                    :class="{
                      'is-invalid': $v.application.supervisor.name.$error,
                      'is-valid':
                        !$v.application.supervisor.name.$error &&
                        $v.application.supervisor.name.$dirty,
                    }"
                  />
                </div>
                <div class="form-group">
                  <label for="supervisor-email">
                    Lärarhandledarens mail
                    <small class="text-muted">(obligatoriskt)</small></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    id="supervisor-email"
                    autocomplete="home email"
                    v-model="application.supervisor.email"
                    @input="$v.application.supervisor.email.$touch"
                    :class="{
                      'is-invalid': $v.application.supervisor.email.$error,
                      'is-valid':
                        !$v.application.supervisor.email.$error &&
                        $v.application.supervisor.email.$dirty,
                    }"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div v-show="step === 2" key="step2">
          <div>
            <h1>Deltagare</h1>
            <div class="row">
              <div class="col">
                <div>
                  <participant
                    class="card my-3"
                    v-for="(participant, idx) in application.participants"
                    :key="idx"
                    :showDelete="application.participants.length > 1"
                    :participant.sync="participant"
                    :schools="schools"
                    :howDidYouHearAboutUsReasons="howDidYouHearAboutUsReasons"
                    :title="`Deltagare ${idx + 1}`"
                    :isDigital="isDigital"
                    @remove="remove(participant, idx)"
                    @newSchool="addSchool"
                  ></participant>
                </div>
                <form>
                  <div
                    class="form-group form-check"
                    v-if="application.participants.length > 1"
                  >
                    <label for="projectPhone">
                      Ange ett (1) telefonnummer som juryn kan nå dig eller din
                      grupp på vid den utsatta tiden för en digital juryintervju
                      om semifinalerna eventuellt behöver ställa om från fysiskt
                      till digitalt genomförande. Obs att ni som grupp måste
                      besluta om endast ett enda telefonnummer som anges.
                      <small class="text-muted">(obligatoriskt)</small>
                    </label>
                    <input
                      id="projectPhone"
                      type="tel"
                      class="form-control"
                      autocomplete="mobile tel"
                      v-model="application.projectPhoneNumber"
                      @input="$v.application.projectPhoneNumber.$touch"
                      :class="{
                        'is-invalid': $v.application.projectPhoneNumber.$error,
                        'is-valid':
                          !$v.application.projectPhoneNumber.$error &&
                          $v.application.projectPhoneNumber.$dirty,
                      }"
                    />
                  </div>
                </form>
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  v-if="application.participants.length < participantLimit"
                  @click="addParticipant()"
                >
                  Lägg till ytterligare deltagare
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-show="step === 3" key="step3">
          <div>
            <h1>Förhandsvisning av anmälan</h1>

            <div class="row">
              <div class="col">
                <div class="card my-3">
                  <div class="card-header">Projekt</div>
                  <div class="card-body">
                    <h2>{{ application.title }}</h2>
                    <p>{{ application.summary }}</p>
                    <div v-if="subject">
                      <span class="font-weight-bold text-muted">Kategori:</span>
                      <span>{{ subject.name }}</span>
                    </div>
                    <span class="font-weight-bold text-muted">Fil:</span>
                    <span>{{ filename }}</span>
                    <div>
                      <span class="font-weight-bold text-muted"
                        >Lärarhandledarens namn:</span
                      >
                      <span>{{ application.supervisor.name }}</span>
                    </div>
                    <div>
                      <span class="font-weight-bold text-muted"
                        >Lärarhandledarens mail:</span
                      >
                      <span>{{ application.supervisor.email }}</span>
                    </div>
                    <div v-if="application.participants.length > 1">
                      <span class="font-weight-bold text-muted"
                        >Gruppens kontaktnummer:</span
                      >
                      <span>{{ application.projectPhoneNumber }}</span>
                    </div>
                  </div>
                </div>
                <div class="card my-3">
                  <div class="card-header">Deltagare</div>

                  <div class="card-body">
                    <div class="row">
                      <div
                        v-for="(participant, idx) in application.participants"
                        :key="idx"
                        class="col-12 col-md-6"
                      >
                        <div class="card my-2">
                          <div class="card-header">
                            {{ participant.firstName }}
                            {{ participant.lastName }}
                            <span
                              class="text-muted"
                              v-if="findSchoolById(participant.schoolId)"
                              >{{ findSchoolById(participant.schoolId).name }} i
                              {{
                                findSchoolById(participant.schoolId).address
                                  .city
                              }}</span
                            >
                          </div>
                          <div class="card-body">
                            <div>
                              <span class="font-weight-bold text-muted"
                                >Epost:</span
                              >
                              <span>{{ participant.email }}</span>
                            </div>
                            <div>
                              <span class="font-weight-bold text-muted"
                                >Födelsedatum:</span
                              >
                              <span>{{ participant.birthDate }}</span>
                            </div>
                            <div v-if="!isDigital">
                              <span class="font-weight-bold text-muted"
                                >Allergier/specialkost:</span
                              >
                              <span>{{ participant.diet }}</span>
                            </div>
                            <div>
                              <span class="font-weight-bold text-muted"
                                >Kön:</span
                              >
                              <span>{{ participant.gender }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-group py-3 w-100">
          <button
            v-if="step > 1"
            class="btn btn-secondary w-50"
            @click="previousStep"
            key="previous-button"
          >
            Föregående steg
          </button>
          <button
            v-if="submitting"
            class="btn btn-disabled btn-primary w-50"
            type="button"
            disabled
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Skickar in anmälan
          </button>
          <button
            v-else-if="step === 3"
            class="btn btn-primary w-50"
            :disabled="submitting"
            @click="
              submitSynchronous();
              sendAJAXData();
            "
          >
            Skicka in anmälan
          </button>
          <button
            v-else
            class="btn btn-primary w-50"
            @click="nextStep"
            key="next-button"
          >
            Nästa steg
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { find, cloneDeep } from "lodash";
// import { Steps, Step, Divider } from "ant-design-vue";
import VueSimpleSuggest from "vue-simple-suggest";
import gql from "graphql-tag";
import {
  required,
  minLength,
  maxLength,
  between,
  requiredIf,
  email,
} from "vuelidate/lib/validators";
import ApplicationFormSchoolPicker from "./ApplicationFormSchoolPicker.vue";
import ApplicationFormFileUpload from "./ApplicationFormFileUpload.vue";
import ApplicationFormParticipant from "./ApplicationFormParticipant.vue";

const snakeCaseKeys = require("snakecase-keys");
const validLocalContest = (value) => value[0].localContestId !== undefined;
const accepted = (value) => {
  return value || false;
};

export default {
  inheritAttrs: true,
  components: {
    VueSimpleSuggest,
    participant: ApplicationFormParticipant,
    "school-picker": ApplicationFormSchoolPicker,
    "project-file-upload": ApplicationFormFileUpload,
  },
  props: {
    competition: Object,
    subjects: Array,
    existingSchools: Array,
    localContests: Array,
    participantLimit: Number,
    howDidYouHearAboutUsReasons: Array,
    numLocalContestPriorities: Number,
  },
  validations: {
    application: {
      title: {
        required,
        maxLength: maxLength(200),
      },
      summary: {
        required,
        maxLength: maxLength(1000),
      },
      report: {
        required,
      },
      subjectId: {
        required,
      },
      acceptedRules: {
        required,
        accepted,
      },
      localContestPriorities: {
        validLocalContest,
      },
      projectPhoneNumber: {
        required: requiredIf(function (_) {
          return this.application.participants.length > 1;
        }),
        minLength: minLength(7),
        maxLength: maxLength(16),
      },

      supervisor: {
        name: {
          required,
        },
        email: {
          required,
          email,
        },
      },
    },
  },
  data() {
    return {
      step: 1,
      submitting: false,
      success: undefined,
      find,
      filename: "",
      application: {
        title: "",
        summary: "",
        misc: "",
        report: "",
        reportLanguage: "Svenska",
        subjectId: "",
        localContestPriorities: [],
        participants: [
          {
            schoolId: 0,
            acceptedTermsAndConditions: true,
            emergencyContacts: {
              firstName: "",
              lastName: "",
              phoneNumber: "",
              relation: "",
            },
            address: { street1: "", zipCode: "", city: "" },
          },
        ],

        isWaterProject: false,
        needsPermit: false,
        permitNumber: "",
        acceptedRules: false,
        requiresElectricityBecause: "",
        acceptedEducationalUse: Boolean,
        acceptedWebsiteVisibility: Boolean,
        projectPhoneNumber: "",
        supervisor: {
          name: "",
          email: "",
        },
      },
      errorMessage: "",
      newSchools: [],
      isDigital: false, // Controls monter info and other factors

      validationFields: {
        1: [
          "title",
          "summary",
          "report",
          "subjectId",
          "localContestPriorities",
          "supervisor",
          "acceptedRules",
        ],
        2: ["projectPhoneNumber"],
        3: [],
      },
    };
  },
  created: function () {
    for (let i = 1; i <= this.numLocalContestPriorities; i++) {
      this.application.localContestPriorities.push({
        priority: i,
      });
    }
  },
  methods: {
    formatEndDate() {
      let date = new Date(this.competition.sign_up_ends_at);
      date.setDate(date.getDate() - 1);
      return date.toLocaleDateString();
    },
    numToOrderNumber(num) {
      if (
        num === 1 ||
        num === 2 ||
        (num > 20 && (num % 10 === 1 || num % 10 === 2))
      ) {
        return `${num}:a`;
      }
      return `${num}:e`;
    },
    getFilteredLocalContests(index) {
      let toRemoveId = this.application.localContestPriorities
        .slice(0, index - 1)
        .map((lc) => lc.localContestId);
      return this.localContests.filter(
        (localContest) => !toRemoveId.includes(localContest.id)
      );
    },
    addSchool(newSchool) {
      this.newSchools = this.newSchools.concat(newSchool);
    },
    addParticipant() {
      this.application.participants.push({
        schoolId: 0,
        acceptedTermsAndConditions: true,
        emergencyContacts: {
          firstName: "",
          lastName: "",
          phoneNumber: "",
          relation: "",
        },
        address: { street1: "", zipCode: "", city: "" },
      });
    },
    submitSynchronous() {
      if (!this.submitting) {
        this.submitting = true;
        this.submitApplication();
      }
    },
    async submitApplication() {
      const application = cloneDeep(this.application);

    
      
      //  application.summary += `\nRapportspråk: ${this.tempReportLanguage}`;
      //  if (application.permitNumber) {
      //    application.summary += `\nTillståndsnummer: ${application.permitNumber}`;
      //  }

      // Detta är en sket-lösning då jag inte får nya data att skickas till databasen.
      // application.summary += ` \n{Rapportspråk: ${this.tempReportLanguage}, Tillståndsnummer: ${application.permitNumber}}`;


      this.$v.$touch();
      if (this.$v.invalid) {
        this.submitting = false;

        return;
      }
      application.localContestPriorities = application.localContestPriorities.filter(
        (elmt) =>
          elmt.localContestId !== "" && elmt.localContestId !== undefined
      );

      let newSchools = [];

      const stripSchool = ({ id, address, name }) => ({ id, address, name });

      for (let school of cloneDeep(this.newSchools)) {
        for (let participant of application.participants) {
          if (participant.schoolId === school.id) {
            newSchools.push(stripSchool(school));
            break;
          }
        }
      }

      if (application.participants.length === 1) {
        application.projectPhoneNumber =
          application.participants[0].phoneNumber;
      }

      this.$apollo
        .mutate({
          mutation: gql`
            mutation($input: SubmitProjectApplicationMutationInput = {}) {
              submitProjectApplicationMutation(input: $input) {
                project {
                  title
                  summary
                  participants {
                    schoolId
                    firstName
                    lastName
                  }
                }
              }
            }
          `,
          variables: {
            input: {
              project: application,
              schools: newSchools,
              competition: this.competition.id,
            },
          },
        })
        .then((data) => {
          this.success = true;
        })
        .catch((r) => {
          console.error(r);
          this.success = false;
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    setFilename(blob) {
      this.filename = blob.filename;
    },
    previousStep() {
      this.step--;
      scroll(0, 0);
      parent.postMessage({ name: "scrollToTop" }, "*");
      this.errorMessage = "";
    },
    nextStep() {
      if (this.isFieldsFilled()) {
        this.step++;
        scroll(0, 0);
        parent.postMessage({ name: "scrollToTop" }, "*");
        this.errorMessage = "";
      } else {
        scroll(0, 0);
        parent.postMessage({ name: "scrollToTop" }, "*");
      }
    },
    sendAJAXData() {
      const csrfToken = document.querySelector('meta[name="csrf-token"]')
        .content;

      fetch("/anmalningar/send_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken, //Something for safety apparently
        },
        body: JSON.stringify({ probably_submitted: "Yes" }), // Data to be sent to get_data in anmalningar_controller.rb
      })
        .then((response) => response.json()) // Parse the response as JSON
        .then((data) => {
          //console.log("Response from server:", data); // For dev, not really needed for en elev
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    isFieldsFilled() {
      if (this.step === 1) {
        for (let field of this.validationFields[1]) {
          if (this.$v.application[field].$invalid) {
            for (let touchField of this.validationFields[1]) {
              this.$v.application[touchField].$touch();
            }
            this.errorMessage =
              "Det verkar som att någonting saknas eller inte är rätt ifyllt. Gå igenom allt igen och dubbelkolla att alla obligatoriska fält är ifyllda.";
            return false;
          }
        }
      }

      if (this.step === 2) {
        for (const child of this.$children) {
          if ("$v" in child && child.$v.$invalid) {
            child.$v.$touch();
            this.errorMessage =
              "Det verkar som att någonting saknas eller inte är rätt ifyllt. Gå igenom alla deltagare igen och dubbelkolla att alla obligatoriska fält är ifyllda.";
            return false;
          }
        }

        for (let field of this.validationFields[2]) {
          if (this.$v.application[field].$invalid) {
            for (let touchField of this.validationFields[2]) {
              this.$v.application[field].$touch();
            }
            this.errorMessage =
              "Det verkar som att någonting saknas eller inte är rätt ifyllt. Gå igenom allt igen och dubbelkolla att alla obligatoriska fält är ifyllda.";
            return false;
          }
        }
      }
      return true;
    },
    getFrameHeight() {
      if (this.$refs.form) {
        return this.$refs.form.scrollHeight;
      }
      if (this.$refs.thanks) {
        return this.$refs.thanks.scrollHeight;
      }
      return 1000;
    },
    remove(participant, idx) {
      const message =
        participant.firstName && participant.lastName
          ? `Är du säker på att du vill ta bort ${participant.firstName} ${participant.lastName} från listan över deltagare?`
          : "Är du säker på att du vill ta bort denna deltagare?";

      if (confirm(message)) {
        this.application.participants.splice(idx, 1);
      }
    },
    postHeight() {
      const height = this.getFrameHeight();
      parent.postMessage({ name: "setHeight", height }, "*");
    },

    findSchoolById(schoolId) {
      return find(this.schools, { id: schoolId });
    },
  },
  computed: {
    subject() {
      const id = this.application.subjectId;
      const subjects = this.subjects;
      const theSubject = find(subjects, { id });
      return theSubject;
    },
    schools() {
      return this.existingSchools.concat(this.newSchools);
    },
  },
  updated() {
    this.$nextTick(function () {
      this.postHeight();
    });
  },
  mounted() {
    this.$nextTick(function () {
      this.postHeight();
    });
  },
};
</script>
